<template>
  <div class="exportable-table-wrap" @mousedown="onClickOutside">
    <div class="flp-panel">
      <div class="flp-panel-top">
        <div class="flp-panel-title">REPORT BUILDER</div>
        <div class="flp-controls">
          <b-select
            class="b-select"
            :options="getReportTypeOptions"
            :selected="selectedReportKind"
            :disabled="loading"
            @change="onChangeReportType"
          />
          <b-select-checkbox
            ref="metricOptionsCont"
            :options="metricsOpts"
            class="media-select"
            :disabled="metricsOptsDisabled"
            :custom-select-all-text="'SELECT / DESELECT ALL'"
            :apply-white-labeling="true"
            component-id="metricOptionsCont"
            @selected-options="onSelectMetricOptions"
          />
          <div class="fright">
            <k-download-btn
              :preparing="downloadBtn.preparing"
              :done="downloadBtn.done"
              :class="{ loading }"
              @click.native="download()"
            />
            <b-email-btn
              :email-status="emailSentStatus"
              :disabled="loading"
              :external-open="openEmailPopup"
              @email-to="emailReport"
            />
            <div v-if="!isPageView" class="frightbtn ex-close" @click="$emit('close')">
              <span
                class="intip4 tb-export close-btn-container"
                data-tool-tip="Close Report Builder"
              >
                <font-awesome-icon :icon="['fal', 'times']" class="check-on checkbox-active" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <b-table-report-builder
        v-if="!loading && rows.length"
        :is-page-view="isPageView"
        :rows="rows"
        :headers="formatTableHeaders()"
        :row-to-scroll-to="scrollToRowIndex"
        :rows-collapsible="selectedReportKind === 'all_touch'"
        :css="reportCss"
        @row-click="handleTableRowClick"
      />
      <div v-else-if="!loading && !rows.length" class="table-no-data txt-align-center">
        No data is available to show
      </div>
      <b-loading-spinner
        v-else
        class="txt-align-center table-loader"
        :enable-custom-message="true"
        :custom-message="`Wow, that's a lot of data! We're grabbing it for you now, hang tight`"
      />
      <div v-if="!loading && rows.length" class="pagination">
        {{ showPagerText() }}
        <font-awesome-icon :icon="['fa', 'chevron-left']" class="pointer" @click="gotoPrev" />
        <font-awesome-icon :icon="['fa', 'chevron-right']" class="pointer" @click="gotoNext" />
      </div>
    </div>
  </div>
</template>

<script>
import { isBlank } from 'adready-api/helpers/common';
import moment from 'moment';
import _ from 'underscore';
import { get, sync } from 'vuex-pathify';
import fileDownload from 'js-file-download';
import advertiserReportsAPI from '~/api/advertiser-reports';
import fetchMediaTypesMethodMixin from '~/components/mixins/fetch-mediatypes-method-mixin';
import fetchIosMethodMixin from '~/components/mixins/fetch-ios-method-mixin';
import fetchEventsMethodMixin from '~/components/mixins/fetch-events-method-mixin';
import { buildQueryString } from '~/helpers/global/url-helpers';
import { formatDateForAPI } from '~/util/apiDateFormat';
import * as util from '~/util/utility-functions';
import {
  REPORT_OPTIONS,
  REPORT_BUILDER_METRIC_OPTIONS,
  REPORT_BUILDER_DISABLED_METRICS,
  REPORT_BUILDER_EXTRA_METRICS,
  ACCOUNTS_TO_SHOW,
  MNI_ACCOUNT_ID,
  MNI_DEMO_ACCOUNT_ID,
  USE_PG_API_FOR_REPORT_BUILDER,
} from '~/constant';

// let initDates;

export default {
  name: 'ExportTable',
  components: {
    BSelect: () => import(/* webpackChunkName: "b-select" */ '~/components/elements/b-select.vue'),
    KDownloadBtn: () =>
      import(/* webpackChunkName: "k-download-btn" */ '~/components/elements/k-download-btn.vue'),
    BEmailBtn: () =>
      import(/* webpackChunkName: "b-email-btn" */ '~/components/elements/b-email-btn.vue'),
    BTableReportBuilder: () =>
      import(
        /* webpackChunkName: "b-table-report-builder" */ '~/components/elements/b-table-report-builder.vue'
      ),
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
    BSelectCheckbox: () =>
      import(
        /* webpackChunkName: "b-select-checkbox" */ '~/components/elements/b-select-checkbox.vue'
      ),
  },
  mixins: [fetchMediaTypesMethodMixin, fetchIosMethodMixin, fetchEventsMethodMixin],
  props: {
    isPageView: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      selectedReportKind: 'creative',
      selectedReportType: 'performance',
      rows: [],
      loading: false,
      loadingTouches: false,
      metricsOpts: [],
      metricsOptsDisabled: false,
      totalRowCount: 0,
      rowOffset: 0,
      limitRowCount: 100,
      downloadBtn: {
        preparing: false,
        done: false,
      },
      scrollToRowIndex: '0',
      emailSentStatus: '',
      openEmailPopup: false,
      reportHeaders: [],
      allEventPerformance: false,
      mniAccount: false,
    };
  },
  computed: {
    advertiser: get('common/advertiser'),
    account: get('common/account'),
    universalPixelId: get('common/universalPixelId'),
    selectedMediaTypes: get('dashboard/GET_SELECTED_MEDIATYPES'),
    selectedPublishers: get('dashboard/GET_SELECTED_PUBLISHERS'),
    selectedCreatives: get('dashboard/GET_SELECTED_CREATIVES'),
    selectedAudiences: get('dashboard/GET_SELECTED_AUDIENCES'),
    selectedIOIds: get('dashboard/GET_SELECTED_IO_IDS'),
    dates: get('dashboard/dates'),
    selectedEvent: get('dashboard/GET_SELECTED_EVENT'),
    showUniques: get('dashboard/filters@showUniques'),
    attribution: get('dashboard/filters@attribution'),
    methodology: get('dashboard/filters@methodology'),
    conversionWindow: get('dashboard/filters@conversionWindow'),
    selectedAdGrpOptStr: get('dashboard/GET_SELECTED_AD_GROUP_STR'),
    selectedExportTableRow: sync('common/selectedExportTableRow'),
    isMniAccount: get('common/isMniAccount'),
    selectedEventLabel() {
      return isBlank(this.selectedEvent.eventLabel) ? 'Event' : this.selectedEvent.eventLabel;
    },
    isCheckoutsEvent() {
      return this.selectedEvent.category.toUpperCase() === 'CHECKOUT';
    },
    isLeadsEvent() {
      return (
        this.selectedEvent.eventLabel.toUpperCase() === 'LEAD' ||
        this.selectedEvent.eventLabel.toUpperCase() === 'LEADS'
      );
    },
    isCheckoutsOrRevenueEvent() {
      return this.selectedEvent.isRevenueEvent || this.isCheckoutsEvent;
    },
    isCheckoutsOrLeadsEvent() {
      return this.isLeadsEvent || this.isCheckoutsEvent;
    },
    reportCss() {
      let css = '';
      if (this.selectedReportType === 'rollup') {
        css = this.selectedEvent.isRevenueEvent ? 'sales-rollup' : 'nonsales-rollup';
      } else {
        css = 'performance-report';
      }
      return css;
    },
    selectedCategoriesLabel() {
      let label = `${this.selectedEventLabel}`;
      if (
        !isBlank(this.selectedEvent.subCategories) &&
        !this.selectedEvent.allSubCategoriesSelected
      ) {
        label = this.selectedEvent.subEventLabels.join(', ');
      }
      if (this.isMniAccount && label === 'Checkouts') {
        label = 'Conversions';
      }
      return label;
    },
    getReportTypeOptions() {
      // if (this.selectedEvent.isFalseEvent) {
      //   return REPORT_OPTIONS;
      // }
      let reportOptions = [...REPORT_OPTIONS];
      if (!ACCOUNTS_TO_SHOW.includes(this.account?.id)) {
        reportOptions = [];
        reportOptions = REPORT_OPTIONS.filter((item) => item.id !== 'channel');
      }
      if (this.isMniAccount) {
        reportOptions = REPORT_OPTIONS.filter((item) => item.id !== 'audience');
        reportOptions.splice(5, 0, { id: 'audience', name: 'Placement', type: 'performance' });
      }
      let result = [...reportOptions];
      if (!this.selectedEvent.isFalseEvent) {
        result = [
          {
            id: 'all_touch',
            name: `${this.selectedCategoriesLabel} All Touch Report`,
            type: 'rollup',
          },
          {
            id: 'last_touch',
            name: `${this.selectedCategoriesLabel} Last Touch Report`,
            type: 'rollup',
          },
          ...reportOptions,
        ];
      }
      return result;
    },
    headers() {
      let opts = JSON.parse(JSON.stringify(this.metricsOpts));
      if (this.selectedReportType !== 'rollup') {
        const reportOption = REPORT_OPTIONS.find((opt) => opt.id === this.selectedReportKind);
        if (reportOption) {
          const extraOption = {
            key: 'name',
            name: reportOption.name,
            value: reportOption.name,
            width: '241px',
            checked: true,
          };
          if (this.selectedReportKind === 'daypart') {
            extraOption.formatter = { type: 'hour' };
          }
          opts = [extraOption, ...opts];
        }
      } else {
        // if selectedReportType = rollup
        const hideHeaders = [];
        if (!ACCOUNTS_TO_SHOW.includes(this.account?.id)) {
          hideHeaders.push('channel');
        }
        // if event != REVENUE then hide revenue column
        // if event != REVENUE AND event != Leads/Checkouts then remove uniqueId column
        if (!this.selectedEvent.isRevenueEvent) {
          hideHeaders.push('revenue');
          if (!this.isCheckoutsOrLeadsEvent) {
            hideHeaders.push('uniqueId');
          }
        }

        // if the event = REVENUE OR event = Checkouts then hide subCategory column
        if (this.isCheckoutsOrRevenueEvent) {
          hideHeaders.push('subCategory');
        }

        opts.forEach((header) => {
          if (hideHeaders.includes(header.key)) {
            header.checked = false;
          }
        });
      }

      return opts.filter((mopt) => mopt.checked && mopt.name !== 'Incremental Reach');
    },
    payload() {
      return {
        advertiser: this.advertiser ? this.advertiser.name : '',
        client: this.account ? this.account.name : '',
        xandrId: this.advertiser ? this.advertiser.xandrAdvertiserId : '',
        mediaType: this.selectedMediaTypes,
        ioIds: this.selectedIOIds,
        startDate: formatDateForAPI(this.dates.startDate),
        endDate: formatDateForAPI(this.dates.endDate),
        pixel: this.universalPixelId,
        event: this.selectedEventLabel,
        category: isBlank(this.selectedEvent.category) ? 'none' : this.selectedEvent.category,
        subCategory: this.selectedEvent.subCategories,
        conversionWindow: this.conversionWindow,
        showUniques: this.showUniques,
        attribution: this.attribution,
        methodology: this.methodology,
        adGroups: this.selectedAdGrpOptStr,
        audience: this.selectedAudiences,
        publisher: this.selectedPublishers,
        creative: this.selectedCreatives,
      };
    },
    // initialDates() {
    //   let initialStartDate = new Date(initDates.startDate);
    //   // initialStartDate = initialStartDate.toLocaleString('en-US', { timeZone: 'America/New_York' });
    //   initialStartDate = formatDateForAPI(initialStartDate);

    //   let initialEndDate = new Date(initDates.endDate);
    //   // initialEndDate = initialEndDate.toLocaleString('en-US', { timeZone: 'America/New_York' });
    //   initialEndDate = formatDateForAPI(initialEndDate);

    //   return { startDate: initialStartDate, endDate: initialEndDate };
    // },
  },
  watch: {
    payload: {
      deep: true,
      async handler(newValue, oldValue) {
        try {
          if (_.isEqual(newValue, oldValue)) {
            return;
          }
          await this.loadTableData(newValue);
        } catch (err) {
          console.error('Error in updating daily view chart ->', err.message, err);
          if (window.$sentry) {
            if (err._reported !== true) {
              window.$sentry.captureException(
                `Error in updating daily view chart [watch: payload] ->${err}`
              );
              err._reported = true;
            }
          }
        }
      },
    },
    selectedEvent: {
      async handler(newValue, oldValue) {
        this.setMetricOpts();
        try {
          if (_.isEqual(newValue, oldValue)) {
            return;
          }
          await this.loadTableData(this.payload);
        } catch (err) {
          console.error('Error in updating export table ->', err.message, err);
          if (window.$sentry) {
            if (err._reported !== true) {
              window.$sentry.captureException(
                `Error in updating export table [watch: selectedEvent] ->${err}`
              );
              err._reported = true;
            }
          }
        }
      },
    },
  },
  async mounted() {
    this.setMetricOpts(true);

    if (this?.account?.id === MNI_ACCOUNT_ID || this?.account?.id === MNI_DEMO_ACCOUNT_ID) {
      this.mniAccount = true;
      this.$store.dispatch('common/setIsMniAccount', true);
    } else {
      this.mniAccount = false;
      this.$store.dispatch('common/setIsMniAccount', false);
    }

    const storedDate = sessionStorage.getItem('selectedDates');
    if (storedDate !== undefined && storedDate !== null) {
      this.$store.set('dashboard/dates', JSON.parse(storedDate));
    }

    // initDates = {
    //   startDate: this.dates.startDate,
    //   endDate: this.dates.endDate,
    // };

    // let initialStartDate = new Date(this.dates.startDate);
    // // initialStartDate = initialStartDate.toLocaleString('en-US', { timeZone: 'America/New_York' });
    // initialStartDate = formatDateForAPI(initialStartDate);

    // let initialEndDate = new Date(this.dates.endDate);
    // // initialEndDate = initialEndDate.toLocaleString('en-US', { timeZone: 'America/New_York' });
    // initialEndDate = formatDateForAPI(initialEndDate);

    // initDates = {
    //   startDate: initialStartDate,
    //   endDate: initialEndDate,
    // };

    // const initialPayload = {
    //   ...this.payload,
    //   startDate: initialStartDate,
    //   endDate: initialEndDate,
    // };
    try {
      // await this.loadTableData(initialPayload);
      await this.loadTableData(this.payload);
    } catch (err) {
      console.error('error mounting export table ->', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(`error mounting export table [mounted] -> ${err}`);
          err._reported = true;
        }
      }
    }
    window.addEventListener('click', this.onClickOutside);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutside);
  },
  methods: {
    onClickOutside(e) {
      const refArray = ['metricOptionsCont'];
      if (e?.composedPath()?.filter((p) => p?.id === 'metricOptionsCont')?.length > 0) {
        this?.$refs.ioOptionDropdown?.closeDropdown();
        return;
      }
      refArray.forEach((ref) => {
        if (
          !(e.target.parentNode?.className === 'norm-option') &&
          !(e.target.parentNode?.className === 'dropdown-menu') &&
          !(e.target?.nodeName === 'path')
        ) {
          this?.$refs[ref]?.closeDropdown();
        }
      });
    },
    formatEventTableHeader() {
      const repheader = JSON.parse(JSON.stringify(this.reportHeaders));

      for (let i = 0; i < repheader.length; i++) {
        repheader[i] = {
          key: repheader[i],
          name: repheader[i],
          value: repheader[i],
          width: '',
          formatter: {
            type: '',
            format: '',
          },
        };
        if (repheader[i].key === 'name') {
          let reportKind = this.selectedReportKind;
          if (this.isMniAccount && reportKind === 'audience') reportKind = 'placement';
          repheader[i].name = reportKind.charAt(0).toUpperCase() + reportKind.slice(1);
          repheader[i].width = '260px';
        } else {
          repheader[i].formatter.format =
            repheader[i].key.includes('_CPA') || repheader[i].key.includes('spend')
              ? '0,0.00'
              : '0,0.[00]';
          repheader[i].formatter.type = 'number';
          repheader[i].width = repheader[i].key === 'spend' ? '100px' : '180px';
          repheader[i].name = repheader[i].key === 'spend' ? '($)Spend' : repheader[i].name;
        }
      }
      return repheader;
    },
    formatTableHeaders() {
      if (!isBlank(this.reportHeaders)) {
        return this.formatEventTableHeader();
      }
      let tempHeaders = [...this.headers];
      // rename video completions rate and video completions to completion rate and completions respectively
      tempHeaders = tempHeaders.map((item) => {
        if (item.key === 'videoCompletionRate') item.name = `(%)Completion Rate`;
        if (item.key === 'videoCompletions') item.name = `Completions`;
        return item;
      });
      const isSelectedTypeAudience = tempHeaders.find((item) => item.name === 'Audience');
      if (this.isMniAccount && isSelectedTypeAudience) {
        tempHeaders = tempHeaders.filter((item) => item.name !== 'Audience');
        tempHeaders.splice(0, 0, {
          key: 'name',
          name: 'Placement',
          value: 'Audience',
          width: '241px',
          checked: true,
        });
        // {key: 'name', name: 'Audience', value: 'Audience', width: '241px', checked: true}
      }
      const stickyHeaderIndex = tempHeaders.findIndex((header) => header.stickyColumnAnchor);
      let stickyWidth = this.selectedReportKind === 'last_touch' ? 0 : 26;
      for (let i = 0; i <= stickyHeaderIndex; i++) {
        tempHeaders[i] = {
          ...tempHeaders[i],
          isSticky: true,
          stickyWidth,
        };
        const tempWidth = tempHeaders[i]?.width ? tempHeaders[i].width.split('px')[0] : 84;
        stickyWidth += Number(tempWidth);
      }
      return tempHeaders;
    },
    validatePerformanceReportParams(payload, advertiser) {
      const advertiserId = advertiser ? advertiser.id : null;
      if (isBlank(advertiserId)) {
        return false;
      }
      const keys = [
        'kind',
        'client',
        'advertiser',
        'xandrId',
        'pixel',
        'category',
        'startDate',
        'endDate',
      ];
      return !util.isAnyBlank(payload, keys);
    },
    validateRollupReportParams(payload, advertiser) {
      const advertiserId = advertiser ? advertiser.id : null;
      if (isBlank(advertiserId)) {
        return false;
      }
      const keys = ['client', 'advertiser', 'xandrId', 'pixel', 'category', 'startDate', 'endDate'];
      return !util.isAnyBlank(payload, keys);
    },
    async emailReport(email = '') {
      const payload = { ...this.payload, emailAddr: email, onceNow: true, emailIt: true };
      this.openEmailPopup = true;
      try {
        await this.loadTableData(payload, 'email');
        this.emailSentStatus = 'success';
      } catch (err) {
        this.emailSentStatus = 'error';
      } finally {
        setTimeout(() => {
          this.emailSentStatus = '';
          this.openEmailPopup = false;
        }, 2000);
      }
    },
    setMetricOpts(isMounted = false) {
      if (this.selectedExportTableRow) {
        this.selectedReportKind = this.selectedExportTableRow;
      }
      const key = `${this.selectedReportType}`;
      let opts = JSON.parse(JSON.stringify(REPORT_BUILDER_METRIC_OPTIONS[key] || []));
      // remove incremental reach
      if (this.selectedReportKind !== 'publisher' && this.selectedReportKind !== 'overall') {
        opts = opts.filter((opt) => opt.key !== 'incrementalReach');
      }
      if (this.selectedEvent.isFalseEvent) {
        opts = opts.filter((opt) => !['checkouts', 'metric', 'pm'].includes(opt.key));
      }
      const disabledOptions = REPORT_BUILDER_DISABLED_METRICS[this.selectedReportKind] || [];
      const isAllEvnOptSelected = this.metricsOpts.filter((op) => op.key === 'ep')?.[0]?.checked;
      if (isAllEvnOptSelected && key !== 'rollup') {
        this.allEventPerformance = true;
        return;
      }
      // rename video completions rate and video completions to completion rate and completions respectively
      opts = opts.map((item) => {
        if (item.key === 'videoCompletionRate') item.value = `(%)Completion Rate`;
        if (item.key === 'videoCompletions') item.value = `Completions`;
        return item;
      });
      opts.forEach((opt) => {
        // if the option is key not found in disabled option then set checked = true
        const isOptionDisabled = disabledOptions.includes(opt.key);
        // const existingMetricOpt = this.metricsOpts.filter((o) => o.key === opt.key);
        // if (existingMetricOpt.length > 0) {
        //   if (!existingMetricOpt[0].checked) {
        //     opt.checked = false;
        //   } else {
        //     opt.checked = !isOptionDisabled;
        //   }
        // } else {
        //   opt.checked = !isOptionDisabled;
        // }
        opt.checked = !isOptionDisabled;
        opt.disabled = isOptionDisabled;

        if (this.selectedExportTableRow) {
          if (['reach', 'frequency'].includes(opt.key)) {
            opt.checked = false;
          } else if (this.selectedEvent.isFalseEvent) {
            if (['videoCompletions', 'videoCompletionRate'].includes(opt.key)) {
              opt.checked = this.selectedEvent.isVcrEvent;
            }
            if (['clicks', 'clickThroughRate'].includes(opt.key)) {
              opt.checked = this.selectedEvent.isCtrEvent;
            }
          } else if (
            ['videoCompletions', 'videoCompletionRate', 'clicks', 'clickThroughRate'].includes(
              opt.key
            )
          ) {
            opt.checked = false;
          }
        }
        if (key === 'performance' && opt.key === 'checkouts') {
          opt.key = this.selectedEvent.isRevenueEvent ? 'revenue' : 'checkouts';
          opt.name = this.selectedEvent.isRevenueEvent ? '($)Rev' : this.selectedEventLabel;
          opt.value = this.selectedEventLabel;
          opt.formatter.format = this.selectedEvent.isRevenueEvent ? '0,0.00' : '0,0.[00]';
        } else if (key === 'performance' && opt.key === 'metric') {
          opt.checked =
            isMounted ||
            this.metricsOpts.filter((oa) => oa.key === 'cpa' || oa.key === 'roas')?.[0]?.checked;
          if (this.selectedEventLabel === 'Incremental Reach') {
            opt.key = 'incrementalReachPercentage';
            opt.name = 'Inc. Reach %';
            opt.formatter.format = '0';
          } else {
            const unit = this.selectedEvent.isRevenueEvent ? '%' : '$';
            opt.key = this.selectedEvent.isRevenueEvent ? 'roas' : 'cpa';
            opt.name = `(${unit})${this.selectedEvent.metric}`;
            opt.formatter.format = this.selectedEvent.isRevenueEvent ? '0' : '0,0.00';
          }

          opt.infinityKey = `${opt.key}Infinity`;

          opt.value = this.selectedEvent.metric;
        }
        if (opt.key === 'ep') {
          opt.checked = false;
        }
      });
      // remove incremental reach %
      if (this.selectedReportKind !== 'publisher' && this.selectedReportKind !== 'overall') {
        opts = opts.filter((opt) => opt.key !== 'incrementalReachPercentage');
      }
      if (this.selectedReportKind === 'overall') {
        REPORT_BUILDER_EXTRA_METRICS.forEach((rbem) => {
          opts.push({ ...rbem, checked: false });
        });
      }
      if (this.selectedEventLabel === 'Incremental Reach') {
        opts.forEach((op) => {
          if (op.key === 'incrementalReachPercentage') {
            op.value = 'Inc. Reach %';
            op.checked = true;
          }
          return op;
        });
      }

      this.metricsOpts = opts.filter((opt) => opt.value && opt.name !== 'Incremental Reach');
    },

    async onChangeReportType(opt) {
      this.allEventPerformance = false;
      this.selectedExportTableRow = '';
      if (this.onChangeReportKind === opt) {
        return;
      }
      this.selectedReportKind = opt;
      const reportOption = this.getReportTypeOptions.find((o) => o.id === this.selectedReportKind);
      this.selectedReportType = reportOption.type || 'performance';
      this.metricsOptsDisabled = this.selectedReportType === 'rollup';
      this.setMetricOpts();
      this.rowOffset = 0;
      this.scrollToRowIndex = '0';
      try {
        await this.loadTableData(this.payload);
      } catch (err) {
        console.error('error loading report builder data on select group by option ->', err);
        throw err;
      }
    },

    showPagerText() {
      const startRowIndex = this.rowOffset + 1;
      let endRowIndex = this.rowOffset + this.limitRowCount;
      endRowIndex = endRowIndex > this.totalRowCount ? this.totalRowCount : endRowIndex;
      return `${startRowIndex} - ${endRowIndex} of ${this.totalRowCount}`;
    },

    async gotoNext() {
      if (this.rowOffset + this.limitRowCount >= this.totalRowCount) {
        return;
      }
      try {
        this.rowOffset += this.limitRowCount;
        await this.loadTableData(this.payload);
      } catch (err) {
        console.error('error going to next page on report builder ->', err);
        throw err;
      }
    },

    async gotoPrev() {
      if (this.rowOffset <= 0) {
        return;
      }
      try {
        this.rowOffset -= this.limitRowCount;
        await this.loadTableData(this.payload);
      } catch (err) {
        console.error('error going to prev page on report builder ->', err);
        throw err;
      }
    },

    async loadTableData(payload, action = 'display') {
      // if (
      //   payload.startDate === formatDateForAPI(initDates.startDate) &&
      //   payload.endDate === formatDateForAPI(initDates.endDate)
      // ) {
      //   payload = {
      //     ...payload,
      //     startDate: this.initialDates.startDate,
      //     endDate: this.initialDates.endDate,
      //   };
      // }
      try {
        if (this.allEventPerformance === true) {
          await this.fetchReportEventPerformance(
            payload,
            this.rowOffset,
            this.limitRowCount,
            action
          );
          return;
        }
        if (this.selectedReportType === 'rollup') {
          await this.fetchReportRollup(payload, this.rowOffset, this.limitRowCount, action);
          return;
        }
        await this.fetchReportPerformance(payload, this.rowOffset, this.limitRowCount, action);
      } catch (err) {
        this.rows = [];
        console.error('error loading report builder data ->', err);
        throw err;
      }
    },

    async handleTableRowClick(payload) {
      if (this.loadingTouches) {
        return;
      }
      this.rows[payload.index].open = !this.rows[payload.index].open;
      if (!this.rows[payload.index].open) {
        this.rows[payload.index].subRowLoading = false;
        this.rows.splice(payload.index + 1, payload.data.timesExposed - 1);
        return;
      }
      try {
        await this.fetchReportRollupTouches(
          {
            ...this.payload,
            ip: payload.data.ip,
            ts: payload.data.pixelTimestampMillis,
          },
          payload.index
        );
      } catch (err) {
        console.error('error fetching rollup touches ->', err);
      }
    },

    async onSelectMetricOptions(options, isSelectAllClicked = false, index = -1) {
      const opts = JSON.parse(JSON.stringify(options));
      if (isSelectAllClicked) {
        opts.forEach((opt) => {
          if (opt.key === 'ep') {
            opt.checked = false;
          }
        });
      } else if (index === 0) {
        if (this.epSelected(opts)) {
          opts.forEach((opt) => {
            if (opt.key !== 'ep') {
              opt.checked = false;
            }
          });
        }
      } else if (opts.filter((op) => op.key !== 'ep').filter((o) => o.checked).length > 0) {
        opts.forEach((opt) => {
          if (opt.key === 'ep') {
            opt.checked = false;
          }
        });
      }

      this.metricsOpts = opts.filter((opt) => opt.value);
      if (this.epSelected(opts)) {
        try {
          this.allEventPerformance = true;
          await this.fetchReportEventPerformance(
            this.payload,
            this.rowOffset,
            this.limitRowCount,
            'display'
          );
        } catch (err) {
          this.rows = [];
          console.error('error loading report builder data ->', err);
          throw err;
        }
      } else {
        try {
          this.allEventPerformance = false;
          await this.fetchReportPerformance(
            this.payload,
            this.rowOffset,
            this.limitRowCount,
            'display'
          );
        } catch (err) {
          this.rows = [];
          console.error('error loading report builder data ->', err);
          throw err;
        }
      }
    },

    epSelected(options) {
      const opts = JSON.parse(JSON.stringify(options));
      let flag = false;
      opts.forEach((opt) => {
        if (opt.key === 'ep' && opt.checked === true) {
          flag = true;
        }
      });
      return flag;
    },

    allSelected(options) {
      const opts = JSON.parse(JSON.stringify(options));
      let flagChecked = false;
      opts.forEach((opt) => {
        if (opt.checked === true) {
          flagChecked = true;
        }
      });
      return flagChecked;
    },
    deSelecAll(options) {
      const opts = JSON.parse(JSON.stringify(options));
      opts.forEach((opt) => {
        if (opt.key !== 'ep') {
          opt.checked = false;
        } else {
          opt.checked = true;
        }
      });
      return opts;
    },

    async download() {
      this.downloadBtn.preparing = true;
      try {
        await this.loadTableData(this.payload, 'download');
        this.downloadBtn.done = true;
      } catch (err) {
        console.error('error downloading file ->', err);
        throw err;
      } finally {
        setTimeout(() => {
          this.downloadBtn.preparing = false;
          this.downloadBtn.done = false;
        }, 1000);
      }
    },

    onSelect(val) {
      this.selectedOpt = val;
    },

    async fetchReportPerformance(payload, offset = 0, limit = 100, action = 'display') {
      //  const fieldData = this.headers.map((header) => header.key);
      // this changes are added to adjust backend filter
      // backend checks for videoCompletionRate and videoCompletions key
      // constant file we updated them to vcr and completeion in PS-1997
      // below changes can be removed once PS-2201 changes accepted
      const fieldData = this.headers.map((header) => {
        if (header.key === 'vcr') {
          return 'videoCompletionRate';
        }
        if (header.key === 'completions') {
          return 'videoCompletions';
        }
        return header.key;
      });
      if (action === 'display') {
        this.loading = true;
      }
      payload = {
        ...payload,
        kind: this.selectedReportKind,
        dimension: this.selectedReportKind,
        pixel: this.universalPixelId,
        fields: fieldData,
        offset,
        limit,
      };
      try {
        const dataValidated = this.validatePerformanceReportParams(payload, this.advertiser);
        let res = {};
        if (dataValidated) {
          const opts = {
            headers: {
              Accept: 'application/json',
            },
            respondHeaders: true,
          };
          if (action === 'download') {
            opts.headers.Accept = 'text/csv';
            opts.responseType = 'blob';
          }
          if (USE_PG_API_FOR_REPORT_BUILDER) {
            res = await advertiserReportsAPI.reportsPerformance(
              this.advertiser.id,
              buildQueryString(payload),
              opts
            );
          } else {
            res = await advertiserReportsAPI.fetchBrandDimensionReport(
              [this.advertiser.id, 1],
              buildQueryString(payload),
              opts
            );
          }

          if (action !== 'display') {
            if (action === 'download') {
              this.saveCSV(res.data, this.genCSVFileName());
            }
            return;
          }
        }
        if (res.headers) {
          this.totalRowCount = dataValidated ? parseInt(res.headers['x-total-count'], 10) : 0;
        }

        res.result = res?.result || [];
        res?.result?.forEach((item) => {
          item.open = false;
          if (this.selectedReportKind === 'publisher' && item.name === '')
            item.name = 'Other Publishers';
        });
        this.reportHeaders = [];
        this.rows = res?.result || [];
      } catch (err) {
        console.error('error in fetching report  table ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(
              `error in fetching report  table [fetchReportPerformance] -> ${err}`
            );
            err._reported = true;
          }
        }
      } finally {
        this.loading = false;
      }
    },

    async fetchReportEventPerformance(payload, offset = 0, limit = 100, action = 'display') {
      const fieldData = this.headers.map((header) => header.key);
      if (action === 'display') {
        this.loading = true;
      }
      payload = {
        ...payload,
        kind: this.selectedReportKind,
        pixel: this.universalPixelId,
        fields: fieldData,
        offset,
        limit,
      };
      try {
        const dataValidated = this.validatePerformanceReportParams(payload, this.advertiser);
        let res = {};
        if (dataValidated) {
          const opts = {
            headers: {
              Accept: 'application/json',
            },
            respondHeaders: true,
          };
          if (action === 'download') {
            opts.headers.Accept = 'text/csv';
            opts.responseType = 'blob';
          }
          res = await advertiserReportsAPI.reportsEventPerformance(
            this.advertiser.id,
            buildQueryString(payload),
            opts
          );
          if (action !== 'display') {
            if (action === 'download') {
              this.saveCSV(res.data, this.genCSVFileName());
            }
            return;
          }
        }
        if (res.headers) {
          this.totalRowCount = dataValidated ? parseInt(res.headers['x-total-count'], 10) : 0;
        }
        res.result = res?.result || [];

        this.rows = res?.result?.records || [];
        this.reportHeaders = res?.result?.headers || [];
      } catch (err) {
        console.error('error in fetching report event table ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(
              `error in fetching report event table [fetchReportPerformance] -> ${err}`
            );
            err._reported = true;
          }
        }
      } finally {
        this.loading = false;
      }
    },
    async fetchReportRollup(payload, offset = 0, limit = 100, action = 'display') {
      if (action === 'display') {
        this.loading = true;
      }
      payload = { ...payload, offset, limit };
      delete payload.showUniques;
      delete payload.attribution;
      delete payload.methodology;
      payload.kind = this.selectedReportKind;
      payload.includeChannel = !!ACCOUNTS_TO_SHOW.includes(this.account?.id);

      if (this.selectedEvent.allSubCategoriesSelected) {
        delete payload.subCategory;
      }

      const opts = {
        headers: {
          Accept: 'application/json',
        },
        respondHeaders: true,
      };
      if (action === 'download') {
        opts.headers.Accept = 'text/csv';
        opts.responseType = 'blob';
      }
      try {
        const dataValidated = this.validateRollupReportParams(payload, this.advertiser);
        let res = {};
        if (dataValidated) {
          res = await advertiserReportsAPI.reportsRollup(
            this.advertiser.id,
            buildQueryString(payload),
            opts
          );
          if (action !== 'display') {
            if (action === 'download') {
              this.saveCSV(res.data, this.genCSVFileName());
            }
            return;
          }
        }
        if (res.headers) {
          this.totalRowCount = parseInt(res.headers['x-total-count'], 10);
        }
        this.reportHeaders = [];
        res.result = res.result || [];
        res.result.forEach((item) => {
          // TODO :: remove the data for channel based on acctId
          item.open = false;
          item.subRowLoading = false;
        });
        this.rows = res.result;
      } catch (err) {
        console.error('error in fetching report rollup table ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(
              `error in fetching report rollup table [fetchReportRollup] -> ${err}`
            );
            err._reported = true;
          }
        }
      } finally {
        this.loading = false;
      }
    },
    async fetchReportRollupTouches(payload, insertFromIndex) {
      this.loadingTouches = true;
      delete payload.showUniques;
      delete payload.attribution;
      delete payload.methodology;
      try {
        this.rows[insertFromIndex].subRowLoading = true;
        const res = await advertiserReportsAPI.reportsRollupTouches(
          this.advertiser.id,
          buildQueryString(payload),
          { respondHeaders: true }
        );
        this.reportHeaders = [];
        res.result = res.result || [];
        this.rows[insertFromIndex].subRowLoading = false;
        this.rows[insertFromIndex].open = true;
        res.result.forEach((item) => {
          item.subRow = true;
          insertFromIndex++;
          this.rows.splice(insertFromIndex, 0, item);
        });
        this.scrollToRowIndex = `${insertFromIndex - res.result.length}`;
      } catch (err) {
        console.error('error in fetching report rollup table ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(
              `error in fetching report rollup table [fetchReportRollupTouches] -> ${err}`
            );
            err._reported = true;
          }
        }
      } finally {
        this.loadingTouches = false;
      }
    },
    saveCSV(data, fileName = 'file.csv') {
      fileDownload(data, fileName);
    },
    genCSVFileName() {
      let { selectedReportKind } = this;
      if (this.isMniAccount && selectedReportKind.toLowerCase() === 'audience') {
        selectedReportKind = 'placement';
      }
      return `${
        this.selectedReportType === 'rollup'
          ? `${this.selectedCategoriesLabel.toLowerCase()}-${this.selectedReportKind}`
          : selectedReportKind
      }-${moment(new Date()).format('yyyy-MM-DD')}.csv`;
    },
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  margin: 0 0.25rem;
  cursor: pointer;
}
.pointer:hover {
  color: var(--primarycolor);
}
.light-theme .pagination {
  color: #555;
}
.pagination {
  margin-top: 0.3rem;
  font-size: 13px;
  color: #aab7bc;
  text-align: right;
}
.b-select {
  width: auto;
  min-width: 200px;
  max-width: 200px;
  margin-right: 20px !important;
  ::v-deep.select {
    margin-top: 0;
    .selected-opt {
      display: block;
      width: 164px !important;
    }
  }
}

::v-deep .dropdown {
  max-width: 200px;
  background-color: #30323a;
  transition: unset !important;
  .select {
    height: 40px;
  }
  .dropdown-menu {
    background-color: #30323a;
    li {
      display: flex;
      padding: 10px 12px !important;
      font-size: 13px !important;
      &:first-child {
        text-transform: capitalize !important;
      }
    }
  }
}
::v-deep .table-wrap {
  .tbl thead {
    background-color: var(--primarycolor);
    z-index: 2;
  }
  th {
    color: var(--buttontext);
  }
  tr.sub-row {
    background-color: #191919;
    border-bottom: 0px;
  }
  table.tbl-export td.short-column {
    svg:hover {
      color: var(--primarycolor);
    }
  }
  td.sub-row {
    &.short-column {
      background-color: #212429;
      border-bottom: 0px;
      border-right: 2px solid var(--primarycolor);
    }
  }
}
.dropdown-menu .exportable-table-wrap {
  height: 400px;
  margin-bottom: 35px;
  overflow: hidden;
  -webkit-transition: height 0.3s ease;
  -moz-transition: height 0.3s ease;
  -ms-transition: height 0.3s ease;
  -o-transition: height 0.3s ease;
  transition: height 0.3s ease;
}
.exportable-table-wrap .flp-panel-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.exportable-table-wrap .dboard-select {
  width: 12rem;
  min-width: 150px;
  height: 42px;
  margin-top: -10px;
  margin-left: 1rem;
  font-size: 14px;
  color: #cad1d6;
  background-color: #30323a;
  border-radius: 4px;
}

.exportable-table-wrap .flp-panel-top .flp-panel-title {
  width: 175px;
  text-align: left;
}
.exportable-table-wrap .flp-controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  ::v-deep .btn-size2 {
    padding: 10px;
    //margin-top: 2px !important;
  }
}
.overflowshow {
  overflow: visible;
}
.light-theme .flp-panel {
  color: #212325;
  box-shadow: 0px 14px 35px -12px rgba(0, 40, 100, 0.17);
  background-color: #fff;
  border: 1px solid rgb(165 173 178 / 22%);
  border-radius: 8px;
}

.flp-panel {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  padding: 30px 30px 10px 30px;
  overflow-x: auto;
  overflow-y: hidden;
  color: #fff;
  background-color: #212429;
  border-radius: 3px;
  transition: background-color 0.3s ease;
}
.flp-panel-title {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.1em;
}
.light-theme {
  .flp-panel-title {
    color: #536176;
    font-weight: 600;
  }
}
.ddselect {
  width: 200px;
  height: 40px;
  margin-left: 1rem;
  background-color: #474a56;
  border-radius: 4px;
}
.light-theme .flp-panel-top {
  margin-bottom: unset;
  border-bottom: unset;
}
.flp-panel-top {
  padding-bottom: 20px;
  margin-bottom: 14px;
  border-bottom: 1px solid #212429;
}
.dboard-select {
  width: 12rem;
  min-width: 150px;
  margin-top: -10px;
  margin-left: 1rem;
  background-color: #474a56;
  border-radius: 4px;
}
.fright {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.ex-close {
  padding: 0 2px 0 8px;
}
.ex-close:hover {
  color: var(--primarycolor);
}
.intip4 {
  position: relative;
  top: 0;
}
.intip4[data-tool-tip]::after {
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 99;
  display: block;
  width: 100px;
  padding: 10px 12px;
  margin-left: -50px;
  font-size: 12px;
  line-height: 1.2em;
  color: #5b5b5b;
  text-align: center;
  pointer-events: none;
  content: attr(data-tool-tip);
  background-color: #fdea85;
  border-radius: 3px;
  transition: ease 0.3s;
  transform: scale(0);
  transform-origin: bottom center;
}
::v-deep table.tbl-export.performance-report th,
::v-deep table.tbl-export.performance-report td,
::v-deep table.tbl-export.sales-rollup th,
::v-deep table.tbl-export.sales-rollup td {
  width: 80px !important;
  padding-right: 10px !important;
}
::v-deep table.tbl-export.nonsales-rollup th,
::v-deep table.tbl-export.nonsales-rollup td {
  padding-right: 10px !important;
}
::v-deep tr {
  border-bottom: 1px solid #27292f;
}

.intip4[data-tool-tip]::after {
  width: 140px;
  height: 34px;
  margin-left: -105px;
  font-size: 12px;
  color: #222;
  pointer-events: none;
  background-color: rgba(245, 245, 250, 0.9);
}
.intip4[data-tool-tip]:hover::after {
  bottom: -30px;
  transform: scale(1);
}
.txt-align-center {
  width: 100%;
  font-size: 13px;
  color: #bac0c5;
  text-align: center;
}
.close-btn-container {
  color: #b5b5b5;
  font-size: 24px;
}
.loading {
  pointer-events: none;
  opacity: 0.5;
}
</style>
